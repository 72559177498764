@import "minima";
@import "chart";

body {
  counter-reset: figures;
  line-height: 1.45;
}

sup {
  vertical-align: top;
  position: relative;
  top: -0.5em;
}

.post-content {
  h2 {
    font-size: 24px;
    margin-top: 25px;
    margin-bottom: 5px;
  }
}

.post-title {
  font-size: 36px;
  margin-bottom: 9px;
}

.post-subtitle {
  color: #555;
  font-size: 18px;
  margin-bottom: 5px;
}

.footnotes {
  border-top: 1px solid #e8e8e8;
  font-size: .9em;
  padding-top: 15px;

  .reversefootnote {
    font-family: sans-serif; // avoid emoji
  }

  p {
    margin-bottom: 3px;
  }
}

.rss-subscribe {
  font-style: italic;
}

.side-by-side > * {
  float: left;
  margin-bottom: 0;
  width: 50%;
}

figure {
  border: 1px solid #e8e8e8;
  counter-increment: figures;
  padding: 10px 0 15px;
}

figcaption:before {
  content: "Figure " counter(figures) ".";
  font-weight: bold;
}

figcaption {
  font-size: .75em;
  margin-left: 3.5rem;
  margin-right: 3.5rem;
}


@media screen and (max-width: 800px) {
  figure {
    padding: 5px 0 10px;
  }

  figcaption {
    margin-left: 10px;
    margin-right: 10px;
  }

  .side-by-side > * {
    float: none;
    width: inherit;
  }
}

code {
  font-family: monospace;
  font-size: inherit;
  background: inherit;
  border-radius: 0;
  border: none;
  padding: 0;

  p & {
    font-size: 15px;
  }
}

pre, pre code {
  font-size: 13px;
}

.anchorjs-link:hover {
  text-decoration: none;
}
